import React, { useState, useEffect } from "react";
import throttle from "lodash/throttle";

import { object, shape } from "prop-types";
import { LazyCollection, LazyLoadImages, replaceAllStoriesInCollection, WithPreview } from "@quintype/components";

import TaboolaScript from "../ads/taboola-ad/load-taboola-ad";
import TaboolaAd from "../ads/taboola-ad";

import { setIsLogin, setUserDetails } from "../store/actions";

import { getCollectionTemplate } from "../get-collection-template";

export const HomePage = ({ data }) => {
  const [renderTaboolaAd, setRenderTaboolaAd] = useState(false);

  useEffect(() => {
    const getScroll = () => {
      if (typeof window !== "undefined") {
        if (window.scrollY > 500) setRenderTaboolaAd(true);
      }
    };
    window.addEventListener("scroll", throttle(getScroll, 500));
    return () => window.removeEventListener("scroll", getScroll);
  }, []);

  return (
    <div className="container">
      <TaboolaScript type="homepage" />
      <LazyLoadImages>
        <LazyCollection
          minHeight={0}
          collection={data?.collection}
          collectionTemplates={getCollectionTemplate}
          lazyAfter={2}
          className="lazy-collection"
        />
      </LazyLoadImages>
      {renderTaboolaAd ? (
        <TaboolaAd container="taboola-below-home-thumbnails" placement="Below Home Thumbnails" mode="thumbnails-a" />
      ) : null}
    </div>
  );
};

HomePage.propTypes = {
  data: shape({
    collection: object
  })
};

export const HomePagePreview = WithPreview(HomePage, (data, story) =>
  Object.assign({}, data, {
    collection: replaceAllStoriesInCollection(data.collection, story)
  })
);
